import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/swiper.min.css';
import "swiper/components/navigation/navigation.min.css"
import 'swiper/components/pagination/pagination.min.css';
import * as serviceWorker from './serviceWorker';
import socket, { QB, APPLICATION_ID,AUTH_KEY,AUTH_SECRET,ACCOUNT_KEY, CONFIG} from './config/socket';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51OmmUdBeAKcnf5VTS2jZeKzZDKtg83wFxJFKTKOj6sqVbRaLzQf25D4QGZB4HicHtl1YwsCUPAIcLI6OhbQJMTqS00BWyZ4qKI"
);
ReactDOM.render(
  <React.StrictMode>
    {/* <MoonPayProvider 
            apiKey="pk_test_YPeuhtzwccO3hxJtZlw28XUuTsYfx" 
            environment="sandbox" 
            debug
        >
             <App />
        </MoonPayProvider> */}
         <Elements stripe={stripePromise}>
           <App />
           </Elements>
 
    
  </React.StrictMode>,
  document.getElementById('root')
);
QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG)
reportWebVitals();
serviceWorker.unregister();
